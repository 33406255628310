import React from 'react'
import Icon1 from '../../images/testimonial.gif'
import {TestimonialsContainer, TestimonialsH1, TestimonialsWrapper, TestimonialsCard, TestimonialsIcon, TestimonialsP} from './TestimonialsElements'

const Testimonials = () => {
  return (
    <TestimonialsContainer id='testimonials'>
      <TestimonialsH1>Testimonials</TestimonialsH1>
      <TestimonialsWrapper>
        <TestimonialsCard>
          <TestimonialsIcon src={Icon1}/>
          {/* <TestimonialsH2></TestimonialsH2> */}
          <TestimonialsP>I can now enjoy my morning tea with real snacks from Reading Kites!</TestimonialsP>
          <TestimonialsP>--  Ravi</TestimonialsP>
        </TestimonialsCard>
        <TestimonialsCard>
          <TestimonialsIcon src={Icon1}/>
          <TestimonialsP>Reading Kites greatly helped me with my interviews for IIMs.</TestimonialsP>
          <TestimonialsP>-- Mridu</TestimonialsP>
        </TestimonialsCard>
        <TestimonialsCard>
          <TestimonialsIcon src={Icon1}/>
          <TestimonialsP>I just can’t wait for the articles to pop-in my Inbox. The best part of the day.</TestimonialsP>
          <TestimonialsP>-- Sanika</TestimonialsP>
        </TestimonialsCard>
      </TestimonialsWrapper>
    </TestimonialsContainer>
  )
}

export default Testimonials
