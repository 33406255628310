import Icon1 from '../../images/Bibliophile-bro.svg'
import Icon2 from '../../images/Ebook-bro.svg'


export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lighttextDesc: true,
  topLine: 'About Us',
  headline: 'Inspire more Learning: “Never run out of things worth talking about”',
  description: 'We are a small team of writers who spend hours on the web looking for the most interesting reads and then summarise them in 90-words. The idea is to prompt new thoughts and always have something to talk about.',
  buttonLabel: 'Get started',
  imgStart: false,
  img: Icon1,
  alt: 'About Us',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjTwo = {
  id: 'resources',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'Sample Content',
  headline: 'Here are some examples of our work and a quick rundown of what we do',
  description: '“A reader lives a thousand lives before he dies…The man who never reads lives only one.”',
  buttonLabel: 'Download Info Sheet',
  buttonLabel2: 'Download Example Sheets',
  imgStart: true,
  img: Icon2,
  alt: 'Piggybanck',
  dark: true,
  primary: true,
  darkText: true
};


export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'Sign Up',
  headline: 'Give yourself a reading treat',
  description: 'Enter your email here to receive the free sample content!',
  buttonLabel: 'Request Sample Content',
  imgStart: false,
  img: '/images/svg-3.svg',
  alt: 'car',
  primary: false,
  darkText: true
};
