import React from 'react'
import Icon1 from '../../images/Summary.svg'
import Icon2 from '../../images/svg-2.svg'
import Icon3 from '../../images/Personalization.svg'
import {ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP} from './ServiceElements'

const Services = () => {
  return (
    <ServicesContainer id='services'>
      <ServicesH1>Our services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1}/>
          <ServicesH2>100 words summary</ServicesH2>
          <ServicesP>
           100 words a day for a month is almost a 150 page book in a month and 12 books in a year, Wasn’t that your New Year’s resolution?          
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
        <ServicesIcon src={Icon2}/>
          <ServicesH2>Engagement metrics</ServicesH2>
          <ServicesP>We take inputs from your engagement level for each article and even help you to remain motivated because we believe a good change is always hard to come by.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3}/>
          <ServicesH2>Personalise your feed</ServicesH2>
          <ServicesP>We take requests depending upon requirements you have. We challenge you to ask for any topics</ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  )
}

export default Services
