import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
background: #000;
height: 85px;
display: flex;
justify-content: space-between;
padding-left: 15vw;
z-index: 12;
@media screen and (max-width: 768px) {
  padding-left: 0;
}

`;


export const NavBtn = styled.nav`
display: flex;
align-items: center;
margin-right: 24px;
/* Third Nav */
/* justify-content: flex-end;
width: 100vw; 
@media screen and (max-width: 768px) {
	display: none;
}*/

@media screen and (max-width: 768px) {
  margin-right: 100px;
}
`;

export const NavBtnLink = styled(Link)`
border-radius: 4px;
background: #808080;
padding: 10px 22px;
color: #000000;
outline: none;
border: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;
/* Second Nav */
margin-left: 24px;
&:hover {
	transition: all 0.2s ease-in-out;
	background: #fff;
	color: #808080;
}
`;


export const Header1 = styled.h1`
  padding: 10px;
`
export const Header2 = styled.h2`
  padding: 10px;
`
export const Header3 = styled.h3`
  padding: 10px;
`
export const Header4 = styled.h4`
  padding: 10px;
`

export const Paragraph = styled.p`
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
`
export const ParagraphBold = styled.p`
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
`


export const PrivacyContainer = styled.div`
  position: relative;
  width: 70%;
  margin: 0 auto;
  font-family: 'Encode Sans Expanded', sans-serif;
  padding: 20px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }

`

export const List = styled.ul`
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
`
export const Item = styled.li`
  margin: 0 0 16px
`