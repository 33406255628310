import React from 'react'
import { Button, DownloadButton } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img } from './InfoElements'
import {  Form, FormInput, FormButton, SignUpMessage} from './SignUpElements'
import { useState } from "react";

//const [message, setMessage] = useState("");

const Info = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, alt, img, primary, dark, dark2 }) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <BtnWrap>
                  <Button to='home'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  primary={primary ? 1 : 0}
                  dark={dark ? 1 : 0}
                  dark2={dark2 ? 1 : 0}
                  >{buttonLabel}
                  </Button>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt}/>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

const InfoWithSignUp = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, alt, img, primary, dark, dark2 }) => {
  const [message, setMessage] = useState("");
  const [successful, setSuccessful] = useState("");
  const [emailIdOfUser, setEmailIdOfUser] = useState('');


  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await fetch("https://l6p79tpezg.execute-api.ap-south-1.amazonaws.com/v1/getinfosheet", {
        method: "POST",
        body: emailIdOfUser,
      });
      let resJson = await res.json();
      console.log(resJson);
      if (res.status === 200) {
        setMessage('Successful! You will receive email in your inbox shortly');
        setSuccessful(true);
        
      } else {
        setSuccessful(false);
        setMessage('Failed! Please try again after sometime.');
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <Form onSubmit={handleSubmit} action='#'>
                  <FormInput 
                    placeholder= 'JonhDoe@gmail.com' 
                    htmlFor='email' required 
                    onChange={event => setEmailIdOfUser(event.target.value)}
                    value={emailIdOfUser}
                  />
                  <FormButton type='submit'>{buttonLabel}</FormButton>
                  <SignUpMessage successful={successful}>{message ? <p>{message}</p> : null}</SignUpMessage>
                </Form>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt}/>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )

};

const InfoWithDownloadFile = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, buttonLabel2, alt, img, primary, dark, dark2 }) => {
  const handleInfoSheetDownload = () => {
    try {  
      fetch('Info Sheet_Reading Kites.pdf').then(response => {
      response.blob().then(blob => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob);
      // Setting various property values
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = 'Info Sheet_Reading Kites.pdf';
      alink.click();
      })
    })
  } catch (err) {
      console.log(err);
    }
  }

  const handleExampleSheetDownload = () => {
    try {  
      fetch('Example Sheet_Reading Kites.pdf').then(response => {
      response.blob().then(blob => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob);
      // Setting various property values
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = 'Example Sheet_Reading Kites.pdf';
      alink.click();
      })
    })
  } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <BtnWrap>
                  <DownloadButton  
                    onClick={handleInfoSheetDownload}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                    >{buttonLabel}
                  </DownloadButton>
                  <DownloadButton 
                    onClick={handleExampleSheetDownload}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >{buttonLabel2}
                  </DownloadButton>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt}/>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )

};


export {Info, InfoWithSignUp, InfoWithDownloadFile}
