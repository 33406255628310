import styled from 'styled-components';

export const Form = styled.form`

  z-index: 1;
  display: grid;  
  
  @media screen and (max-width: 480px) {
    padding: 32px 32px;
  }
  `

export const FormInput = styled.input`
  padding: 16px 16px;
  margin-bottom: 32px;
  border-radius: 4px;
`
export const FormButton = styled.button`
  background: #01bf71;
  padding: 16px 0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
`

export const SignUpMessage = styled.h3`
  
  margin-bottom: 32px;
  border-radius: 4px;
  color: ${({successful}) => (successful ? '#01bf71' : '#FF0000')};
`

export const Successful = styled.p`
color: #00FF00;
`

export const Unsuccessful = styled.p`
color: #FF0000;
`