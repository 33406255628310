import React from 'react'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'
import { FooterContainer, FooterWrap, SocialLogo, SocialMedia, SocialMediaWrap, WebsiteRights, Address, Contact, ContactWrap, SocialIcons, SocialIconLink, NavBtnLink } from './FooterElements'
import { animateScroll as scroll } from 'react-scroll';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  }
  const toggleTop = () => {
    scroll.scrollToTop();
  }

  return (
    <FooterContainer>
      <FooterWrap>
      <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>
            Reading Kites
            </SocialLogo>
            <WebsiteRights>Reading Kites © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
            <SocialIcons>
              <SocialIconLink href='https://m.facebook.com/profile.php?id=100089602804804' target='_blank' arial-label='Facebook'>
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href='https://www.instagram.com/reading_kites/' target='_blank' arial-label='Instagram'>
                <FaInstagram/>
              </SocialIconLink>
              <SocialIconLink href='https://twitter.com/reading_kites' target='_blank' arial-label='Twitter'>
                <FaTwitter/>
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
          <SocialMediaWrap>
            <ContactWrap>
             <Address> Software Technology Parks of India, Bengaluru</Address>
             <Contact> Mail us: editor@readingkites.com</Contact>
            </ContactWrap>
            <ContactWrap>
              <NavBtnLink to='/privacy-policy' onClick={toggleTop}>Privacy Policy</NavBtnLink>
            </ContactWrap>               
          </SocialMediaWrap>

      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
