import React from 'react'
import Privacy from '../components/Privacy'

const PrivacyPage = () => {
  return (
    <>
    <Privacy /> 
    </>
  )
}
export default PrivacyPage
